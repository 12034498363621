<template>
  <div class="main__content archive">
    <h4 class="title-h4 archive__title">
      {{ $t('statistic.archive') }}
    </h4>
    <div class="archive__list" v-if="!!archivesList.length">
      <div
        class="archive__list-item"
        v-for="item in archivesList"
        :key="item.period"
      >
        <div class="archive__list-title">{{ item.period }}</div>
        <FileUpload
          v-model="item.file"
          is-download-only
          class="statistic__file-upload"
        />
      </div>
    </div>
    <div class="archive__no-data" v-else>{{ $t('table.no_data') }}</div>
    <div class="archive__buttons-wrapper">
      <Button
        class="statistic__button"
        @click.native="
          $router.push({ name: 'content-administrator_statistics-list' })
        "
      >
        {{ $t('buttons.back_to_list') }}
      </Button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { createFile } from '@/library/helpers';
import FileUpload from '@/elements/FileUpload.vue';
export default {
  components: { FileUpload },

  data() {
    return {
      archivesList: [],
    };
  },
  async created() {
    if (!!this.$route.params.type_name) {
      await this.$store.dispatch(
        'getTourismReportArchiveContentAdmin',
        this.$route.params.type_name
      );
    } else {
      this.$router.push({ name: 'content-administrator_statistics-list' });
    }
    this.setArchivesList();
  },

  computed: {
    ...mapGetters(['getTourismReportArchiveAdmin']),
  },

  methods: {
    setArchivesList() {
      this.getTourismReportArchiveAdmin?.map(async (el) => {
        let obj = {
          period: await el.period,
          file: [
            await createFile(el.attachment.original_url, el.attachment.mime),
          ],
        };
        await this.archivesList.push(obj);
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.archive__title
  margin-top: 50px
  margin-bottom: 50px
.archive__list
  display: flex
  flex-wrap: wrap
.archive__list-item
  margin-right: 30px
  margin-bottom: 30px
  max-width: 380px
  @include s
    width: 300px
  @include xs
    max-width: 100%
    width: 100%
.archive__list-title
  color: $gray_notification
  margin-bottom: 5px
.archive__buttons-wrapper
  display: flex
  justify-content: flex-end
  margin-bottom: 50px
  @include xs
    justify-content: center
.archive__no-data
  text-align: center
  padding: 20px 0 50px 0
  color: #C4C4C4
  font-size: 28px
</style>
